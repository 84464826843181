<template>
  <section>
    <DailyReportFilter
      @filter="onFilterSubmitted"
      @site-change="onSiteChange"
      class="daily-report-filter"
    >
      <template #buttons>
        <div
          v-show="!loading"
          class="btn btn-info"
          @click="unparseDataToCsv"
        >
          {{ `Export CSV` }}
        </div>
      </template>
    </DailyReportFilter>
    <Preloader v-if="loading" />
    <p class="alert alert-info" v-if="!loading && videoList.length === 0">
      {{ $t('result_not_found') }}
    </p>
    <VideoStatsDailyReportTable v-if="!loading && videoList.length > 0" :videos="videoList" />
  </section>
</template>

<script>
import moment from 'moment'
import DailyReportFilter from '@/components/video/videoStats/DailyReportFilter'
import Preloader from '@/components/preloader/Preloader'
import VideoStatsDailyReportTable from '@/components/table/VideoStatsDailyReportTable'
import { dataHubVideoApi } from '@/api/datahub'
import NotifyService from '@/services/NotifyService'

export default {
  name: 'VideoDailyReport',
  components: {
    VideoStatsDailyReportTable,
    DailyReportFilter,
    Preloader
  },
  computed: {
    videoList () {
      const videoList = [...this.videos]
      videoList.push(this.getTotals(videoList))
      return videoList
    }
  },
  data () {
    return {
      loading: false,
      videos: [],
      dateFrom: null,
      dateTo: null
    }
  },
  methods: {
    getTotals (videos) {
      return {
        videoPlays: videos.reduce((sum, video) => sum + video.videoPlays, 0),
        hero: videos.reduce((sum, video) => sum + video.hero.reduce((sum, hero) => sum + hero.videoPlays, 0), 0),
        article: videos.reduce((sum, video) => sum + video.article.reduce((sum, article) => sum + article.videoPlays, 0), 0),
        gallery: videos.reduce((sum, video) => sum + video.gallery.reduce((sum, gallery) => sum + gallery.videoPlays, 0), 0)
      }
    },
    FetchTop10 (departmentIds = []) {
      this.loading = true
      this.videos = []

      const formatDate = (date) => moment(date).format('YYYY-MM-DD')
      const payload = {
        fromDate: formatDate(this.dateFrom),
        toDate: formatDate(this.dateTo),
        limit: 10,
        departmentIds
      }
      // Fetch top 10 videos
      dataHubVideoApi().post('/report', payload)
        .then(response => {
          this.videos = response.data
        })
        .catch(error => {
          console.error(error)
          NotifyService.setErrorMessage(error)
        })
        .finally(() => {
          this.loading = false
        })
    },
    onFilterSubmitted (data) {
      this.dateFrom = data.dateFrom
      this.dateTo = moment(data.dateTo).add(1, 'days').toISOString()
      this.FetchTop10(data.departmentIds)
    },
    onSiteChange (departmentIds) {
      this.FetchTop10(departmentIds)
    },
    unparseDataToCsv () {
      const rows = []

      rows.push([
        'site',
        'author',
        'title',
        'videoPlays',
        'avgCompletePercent',
        'hero',
        'article',
        'gallery'
      ])

      const reduceBrandVideoPlays = list => list
        ? list.reduce((acc, item) => `${acc}${acc ? ', ' : ''}${item.brand} - ${item.videoPlays}`, '')
        : ''
      this.videos.forEach(v => {
        const brands = v.brands?.join(', ') ?? ''
        const hero = reduceBrandVideoPlays(v.hero)
        const article = reduceBrandVideoPlays(v.article)
        const gallery = reduceBrandVideoPlays(v.gallery)

        const row = [
          brands,
          v.author,
          v.title,
          v.videoPlays,
          v.avgCompletePercent,
          hero,
          article,
          gallery
        ]

        rows.push(row)
      })

      let csv = ''
      rows.forEach(row => {
        csv = `${csv}${row.join(';')}\n`
      })

      var csvData = new Blob([csv], { type: 'text/csv;charset=utf-8;' })
      var csvURL = null
      const fileName = 'videostats-daily-report.csv'

      if (navigator.msSaveBlob) {
        csvURL = navigator.msSaveBlob(csvData, fileName)
      } else {
        csvURL = window.URL.createObjectURL(csvData)
      }
      var tempLink = document.createElement('a')
      tempLink.href = csvURL
      tempLink.setAttribute('download', fileName)
      tempLink.click()
    }
  }
}
</script>

<style scoped lang="scss">
.daily-report-filter {
  margin-bottom: 1rem;
}
</style>

<template>
  <AbstractFilter
    :on-filter="() => {}"
    :on-filter-reset="() => {}"
    :show-search-button="false"
    filter-panel-class="filter-panel-daily-report"
  >
    <template>
      <Select
        v-model="filter.site"
        :options="sites"
        id="dialyReportFilter_site"
        :label="$t('videoStats.filter.department')"
        disable-form-group
        @change="submitSiteChange"
      />
      <DatePicker
        type="date"
        v-model="filter.dateFrom"
        id="filter_date_from"
        disable-form-group
        :label="$t('videoStats.filter.date_from')"
        @change="submitFilter"
      />
      <DatePicker
        type="date"
        v-model="filter.dateTo"
        id="filter_date_to"
        disable-form-group
        :label="$t('videoStats.filter.date_to')"
        @change="submitFilter"
      />
    </template>
    <template #resetButtons>
      <slot name="buttons" />
    </template>
  </AbstractFilter>
</template>

<script>
import DatePicker from '@/components/form/Datepicker'
import Select from '@/components/form/select/Select'
import moment from 'moment'
import AbstractFilter from '@/components/filter/AbstractFilter'

export default {
  name: 'DailyReportFilter',
  components: {
    AbstractFilter,
    Select,
    DatePicker
  },
  data () {
    return {
      filter: {
        dateFrom: moment().set({ hour: 0, minutes: 0, second: 0, millisecond: 0 }).toISOString(),
        dateTo: moment().set({ hour: 0, minutes: 0, second: 0, millisecond: 0 }).toISOString(),
        site: null
      }
    }
  },
  computed: {
    sites () {
      const sitesToRemove = ['automix', 'peknebyvanie', 'mollietvori', 'mamina', 'zazracnysvet', 'adam', 'geo']
      const sites = this.$store.getters['site/allSorted']()
      return sites.filter(site => !sitesToRemove.includes(site.name))
    }
  },
  mounted () {
    this.submitFilter()
  },
  methods: {
    getDepartmentIdsBySite (siteId) {
      const departments = this.$store.getters['department/departmentsBySite'](siteId)
      return departments.map(department => department.id)
    },
    submitFilter () {
      const filter = { ...this.filter, departmentIds: this.getDepartmentIdsBySite(this.filter.site) }
      this.$emit('filter', filter)
    },
    submitSiteChange (siteId) {
      this.$emit('site-change', this.getDepartmentIdsBySite(siteId))
    }
  }
}
</script>

<style lang="scss">
.filter-panel-daily-report {
  @include bp(0 7) {
    grid-template-columns: 1fr;
  }
  @include bp(8) {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
</style>
